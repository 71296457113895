<template>
  <v-main>
    <v-app-bar class="home-navigation" dense dark elevate-on-scroll>
      <div class="home-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img src="@/assets/ukpl-logo-new.png" alt="UKPL logo" contain class="ukpl-home-navigation-logo" />
          </a>
        </div>
      </div>
    </v-app-bar>

    <v-container class="ukpl-login-card-container" fluid>
      <v-row align="center" justify="center" class="margin20">
        <v-col cols="12" sm="12" md="12">
          <v-card class="ukpl-login-card">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-form class="ukpl-login-card-signin" ref="signinform" v-model="signinvalid"
                      @submit.prevent="signin">
                      <v-card-text>
                        <div class="ukpl-login-card-signin-logo-wrap">
                          <v-img src="@/assets/ukpl-logo-new.png" class="ukpl-login-card-signin-logo" />
                        </div>

                        <h4 class="text-center mlt-4 mt-3">
                          Enter your login details below to sign in
                        </h4>
                        <v-text-field :rules="mandatoryRules" v-model="loginuser.username"
                          label="Username or Email Address" name="username" prepend-icon="person" type="text"
                          color="blue accent-3" class="ukpl-login-card-signin-text-field" />
                        <v-text-field :rules="mandatoryRules" v-model="loginuser.password" id="password"
                          label="Password" name="password" prepend-icon="lock" type="password" color="blue accent-3"
                          class="ukpl-login-card-signin-text-field" />
                        <v-alert type="error" transition="scale-transition" :value="loginfail">
                          Username or Password Invalid.
                        </v-alert>
                      </v-card-text>
                      <div class="text-center mb-3">
                        <v-btn class="red-btn-primary" rounded dark @click="step = 3">Forgot Your Password</v-btn>
                      </div>
                      <div class="text-center mb-3">
                        <v-btn class="blue-btn-primary" rounded color="blue accent-3" dark type="submit">Sign In</v-btn>
                      </div>
                      <div class="spacer20"></div>
                    </v-form>
                  </v-col>
                  <!-- Sign in -->
                  <v-col cols="12" md="4" class="ukpl-login-card-register">
                    <v-card-text class="white--text mt-12">
                      <h1 class="text-center display-1">Sign In</h1>
                      <h5 class="text-center">
                        Click Below to Create an Account
                      </h5>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn class="blue-btn-primary-outline" dark @click="step++">REGISTER</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col cols="12" md="4" class="ukpl-blue-bg">
                    <v-card-text class="white--text mt-8">
                      <h1 class="text-center display-1">Register</h1>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn class="mb-3" rounded outlined dark @click="step--">BACK TO SIGN IN</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card-text class="mt-4">
                      <v-img class="ukpl-home-registration-logo" src="@/assets/ukpl-logo-new.png" />
                      <h1 class="text-center display-2 blue--text text--accent-3">
                        Create Account
                      </h1>
                      <h4 class="text-center mt-4">
                        Enter Details To Register
                      </h4>
                      <v-form ref="registerform" v-model="registervalid">
                        <v-text-field :rules="mandatoryRules" v-model="registeruser.firstname" label="First Name"
                          name="forename" prepend-icon="person" type="text" color="blue accent-3" />
                        <v-text-field :rules="mandatoryRules" v-model="registeruser.lastname" label="Last Name"
                          name="surname" prepend-icon="person" type="text" color="blue accent-3" />
                        <v-text-field :rules="[rules.required, rules.nospace]" v-model="registeruser.username"
                          label="Username" name="username" prepend-icon="fa-user-circle" type="text"
                          color="blue accent-3" />
                        <v-text-field :rules="emailRules" v-model="registeruser.email" label="Email" name="email"
                          prepend-icon="email" type="text" color="blue accent-3" />
                        <v-text-field :rules="[rules.required, rules.min]" v-model="registeruser.password"
                          id="registerpassword" label="Password" name="registerpassword" prepend-icon="lock"
                          type="password" color="blue accent-3" />
                        <v-text-field v-model="confirmpassword" :rules="[
                          rules.required,
                          rules.min,
                          passwordConfirmationRule,
                        ]" id="confirmpassword" label="Confirm Password" name="confirmpassword" prepend-icon="lock"
                          type="password" color="blue accent-3" />
                      </v-form>
                      <v-alert type="error" transition="scale-transition" :value="registerfail">
                        {{ registerfailmessage }}
                      </v-alert>
                      <v-snackbar :timeout="2000" v-model="registersuccess" color="green accent-4" rounded="pill"
                        elevation="24">
                        User Registered Successfully. Redirecting to Login page.
                        Please login with your username and password.
                      </v-snackbar>
                    </v-card-text>
                    <div class="text-center mb-4">
                      <v-btn class="blue-btn-primary" rounded color="blue accent-3" dark @click="register">Register
                        Account</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="3">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-card-text class="mt-4">
                      <v-img src="@/assets/ukpl-logo-new.png" />
                      <h4 class="text-center mlt-4 mt-3">
                        Enter your Email address to reset your password
                      </h4>
                      <v-form ref="resetpasswordform" v-model="resetpassword">
                        <v-text-field :rules="mandatoryRules" v-model="resetpasswordemail" label="Email Address"
                          name="email" prepend-icon="email" type="text" color="blue accent-3" />
                      </v-form>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="4" class="ukpl-reset-password-card">
                    <div class="text-center mb-3 mt-12">
                      <v-btn rounded color="blue accent-3" dark @click="sendresetpassword()">Reset Password</v-btn>
                    </div>
                    <div class="text-center mb-3 mb-8">
                      <v-btn rounded color="blue accent-3" dark @click="step = 1">Cancel</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import User from "../model/user";
import authservice from "../services/auth.service";
import UserService from "../services/user.service";

export default {
  name: "Home",
  data: () => ({
    resetpassword: "",
    resetpasswordemail: "",
    loginfail: false,
    registersuccess: false,
    registerfail: false,
    registerfailmessage: "",
    loginuser: new User("", "", "", "", ""),
    registeruser: new User("", "", "", "", ""),
    step: 1,
    password: "",
    registerpassword: "",
    confirmpassword: "",
    emailRules: [],
    mandatoryRules: [],
    rules: {
      required: (value) => !!value || "Field is Required.",
      min: (v) => v.length >= 8 || "Must be at least 8 characters",
      emailMatch: (v) => /.+@.+/.test(v) || "E-mail must be valid",
      nospace: (v) => /^\S*$/.test(v) || "Username cannot contain spaces",
    },
    signinvalid: true,
    registervalid: true,
  }),
  props: {
    source: String,
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.registeruser.password === this.confirmpassword ||
        "Password must match"
      );
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    sendresetpassword() {
      authservice.resetPassword(this.resetpasswordemail);
      this.step = 1;
    },
    signin() {
      this.loginfail = false;
      if (!this.$refs.signinform.validate()) {
        return false;
      }
      if (this.loginuser.username && this.loginuser.password) {
        this.$store.dispatch("auth/login", this.loginuser).then(
          () => {
            console.log("login successful, checking if user details complete");
            UserService.getUserDetails().then(
              (response) => {
                if (
                  this.isUserDataPopulated(response.data)
                  &&
                  (!this.isPopulated(response.data.disclaimer) ||
                    response.data.disclaimer === false)
                ) {
                  this.$router.push("/disclaimer").catch(() =>{});
                } else if (
                  this.isUserDataPopulated(response.data)
                  &&
                  (this.isPopulated(response.data.disclaimer) ||
                    response.data.disclaimer === true)
                  && response.data.cardpurchasecount === 0
                ) {
                  this.$router.push("/idcard").catch(() =>{});
                } else {
                  this.$router.push("/profile").catch(() =>{});
                }
              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.loading = false;
              }
            );
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            this.loginfail = true;
          }
        );
      }
    },
    isUserDataPopulated(data) {
      if (
        this.isPopulated(data.username) &&
        this.isPopulated(data.firstname) &&
        this.isPopulated(data.lastname) &&
        this.isPopulated(data.gender) &&
        this.isPopulated(data.dateOfBirth) &&
        this.isPopulated(data.contactNumber) &&
        this.isPopulated(data.addressline1) &&
        this.isPopulated(data.town) &&
        this.isPopulated(data.postcode) &&
        this.isPopulated(data.ukpsf) &&
        this.isPopulated(data.file)
      ) {
        return true;
      } else {
        return false;
      }

    },
    isPopulated(userfield) {
      if (
        typeof userfield !== "undefined" &&
        userfield !== "" &&
        userfield != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    register() {
      this.registerfail = false;
      this.registersuccess = false;
      if (!this.$refs.registerform.validate()) {
        return false;
      }

      this.$store
        .dispatch("auth/register", this.registeruser)
        .then((response) => {
          this.message = response.message;
          this.registersuccess = true;
        })
        .catch((error) => {
          this.registerfailmessage = error.response.data.message;
          this.registerfail = true;
        });
    },
  },
  mounted: function () {
    this.nameRules = [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ];
    this.mandatoryRules = [(v) => !!v || "Field is required"];
    this.emailRules = [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ];
  },
  watch: {
    registersuccess: function () {
      if (this.registersuccess === false) {
        this.step = 1;
      }
    },
  },
};
</script>
