import api from './api';

const UKPSF_URL = 'https://cors-anywhere.herokuapp.com/http://ukpsf.com/wp-json/api/checkPlayer/';

class UserService {
  getUserDetails() {
    return api.get('secure/user');
  }

  save(userdetails) {
    return api.post('secure/user', {
      username: userdetails.username,
      email: userdetails.email,
      firstname: userdetails.firstname,
      lastname: userdetails.lastname,
      gender: userdetails.gender,
      dateOfBirth: userdetails.dateOfBirth,
      contactNumber: userdetails.contactNumber,
      addressline1: userdetails.addressline1,
      addressline2: userdetails.addressline2,
      town: userdetails.town,
      county: userdetails.county,
      postcode: userdetails.postcode,
      ukpsf: userdetails.ukpsf,
      teamid: userdetails.teamid,
      divid: userdetails.divid,
      teamcaptain: userdetails.teamcaptain,
      lastyearsdivision: userdetails.lastyearsdivision,
      disclaimer: userdetails.disclaimer,
      disclaimerdate: userdetails.disclaimerdate,
      jerseynumber: userdetails.jersey
    });
  }

  changePassword(userdetails) {
    return api.post('secure/user/password', {
      password: userdetails.password
    });
  }

  checkUKPSF(ukpsfnumber) {
    return api.get(UKPSF_URL + ukpsfnumber);
  }

  isTeamCaptain() {
    return api.get('secure/user/iscaptain');
  }

  purchaseCard(orderData) {
    return api.post('secure/user/purchasecard', {
      transactionId: orderData.id,
      orderDate: orderData.create_time
    });
  }

  signDisclaimer(userDetails) {
    return api.post('secure/user/disclaimer', {
      disclaimer: userDetails.disclaimer,
      disclaimerdate: userDetails.disclaimerdate,
      emergencycontactname: userDetails.emergencycontactname,
      emergencycontactnumber: userDetails.emergencycontactnumber,
      divid: userDetails.divid,
      teamid: userDetails.teamid
    });
  }
}

export default new UserService();