import axiosInstance from "./api";
import TokenService from "./token.service";
import router from "../router";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      console.log(originalConfig);
      if (originalConfig.url !== "/auth/signin" && err.response) {
        console.log(err.response);
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
            store.dispatch("auth/logout");
            router.push("/").catch(() =>{});
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;